import React from "react";
import { useNavigate } from "react-router-dom";

import DebbieLogo from "../assets/DebbieLogo.png";

import { auth, firebase } from '../firebase';
import StyledFirebaseAuth from '../firebase/StyledFirebaseAuth';
import { loginStorage } from "../utils/storage";

import { Typography, Box } from "@mui/material";

import makeStyles from '@mui/styles/makeStyles';
const useStyles = makeStyles((theme: any) => ({
	wrapper: {
		backgroundColor: theme.palette.secondary.main,
		height: "100%"
	},
	loginWrapper: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center",
	},
	logo: {
		margin: "10px 0",
		width: 250,
	},
	title: {
		fontWeight: 500,
		margin: "15px 0",
	}
}))

export default function Login() {
	const classes = useStyles();
	const navigate = useNavigate();

	const authUIConfig = {
		signInFlow: 'popup',
		tosUrl: 'https://debbie.chat/',
		privacyPolicyUrl: function () {
			window.location.assign('https://debbie.chat');
		},
		signInSuccessUrl:"/",
		callbacks: {
			signInSuccessWithAuthResult: async (authResult: any) => {
				console.log("authResult", authResult)
				const id = authResult.user.phoneNumber
				loginStorage("user", { id })
				navigate("/")
				return false
			},
		},
		signInOptions: [
			{
				provider: firebase.auth.PhoneAuthProvider.PROVIDER_ID,
				recaptchaParameters: {
					type: 'image', // 'audio'
					size: 'normal', // 'invisible' or 'compact'
					badge: 'bottomleft' //' bottomright' or 'inline' applies to invisible.
				},
				defaultCountry: 'GB',
			},
		]
	}

	return (
		<Box className={classes.wrapper}>
			<Box className={classes.loginWrapper}>
				<img src={DebbieLogo} alt="Debbie" className={classes.logo} />
				<Typography variant="h5" className={classes.title}>
					Login
				</Typography>
			</Box>
			<StyledFirebaseAuth uiConfig={authUIConfig} firebaseAuth={auth} />
		</Box>
	);
};