import red from "@mui/material/colors/red";

// https://www.w3schools.com/colors/colors_picker.asp
const defaultTheme = {
	typography: {
		// useNextVariants: true,
		fontFamily: [
			"Montserrat", 'Segoe UI', 'Roboto', 'Oxygen',
			'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
			'sans-serif'
		].join(",")
	},
	palette: {
		primary: {
			light: '#195b9a',
			main: '#154e83',
			dark: '#12416e',
			contrastText: '#fff',
		},
		secondary: {
			light: '#ffffff',
			main: '#F5F5F5',
			dark: '#808080',
			contrastText: '#fff',
		},
		error: red,
		contrastThreshold: 3,
		tonalOffset: 0.2
	},
};

export default defaultTheme;
