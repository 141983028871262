import React, { useEffect, useState } from 'react';
// import { useNavigate } from "react-router-dom";

import DebbieLogo from "../assets/DebbieLogo.svg";
import PeopleHubLogo from "../assets/peoplehub.svg";
import EUSocialFund from "../assets/EUSocialFund.svg";
import Hi9Logo from "../assets/Hi9_logo.png";
import DebbieDot from "../assets/debbie-dot.svg";

import {
	Box, Typography, Drawer, Divider, TextField, InputAdornment, Button, Link,
	Menu, MenuItem, ListItemIcon, ListItemText,
} from '@mui/material'
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import PublicIcon from '@mui/icons-material/Public';
import InfoIcon from '@mui/icons-material/Info';
// import LoginIcon from '@mui/icons-material/Login';
// import LogoutIcon from '@mui/icons-material/Logout';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import MicRoundedIcon from '@mui/icons-material/MicRounded';
import RssFeedIcon from '@mui/icons-material/RssFeed';
import DescriptionIcon from '@mui/icons-material/Description';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import TextIncreaseIcon from '@mui/icons-material/TextIncrease';
import TextDecreaseIcon from '@mui/icons-material/TextDecrease';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import ClearIcon from '@mui/icons-material/Clear';

// import { auth } from '../firebase';
// import { getStorage, logoutStorage } from '../utils/storage';
import { detectIntent } from '../api';

import { isMobile, isIOS } from 'react-device-detect';

import makeStyles from '@mui/styles/makeStyles';

const FULL_WIDTH = 250
const SMALL_WIDTH = 160

const useStyles = makeStyles((theme: any) => ({
	wrapper: {
		width: "100%",
		display: "flex",
		textAlign: "center",
	},
	leftSection: {
		backgroundColor: theme.palette.primary.main,
		height: "100%",
		display: "flex",
		color: "white",
		overflow: "auto",
	},
	rightSection: {
		backgroundColor: theme.palette.primary.main,
		display: "flex",
		height: "100%",
		color: "white",
		padding: "20px 20px",
		overflow: "auto",
	},
	nonDrawerSection: {
		minWidth: FULL_WIDTH,
		maxWidth: FULL_WIDTH,
		[theme.breakpoints.down('md')]: {
			display: "none",
		},
		[theme.breakpoints.down(1050)]: {
			width: SMALL_WIDTH,
		},
	},
	phLogo: {
		width: 160,
		[theme.breakpoints.down('md')]: {
			width: 160,
		},
		[theme.breakpoints.down(1050)]: {
			width: 110,
		},
	},
	euLogo: {
		width: 200,
		[theme.breakpoints.down('md')]: {
			width: 200,
		},
		[theme.breakpoints.down(1050)]: {
			width: 130,
		},
	},
	debbieLogo: {
		width: 160,
		[theme.breakpoints.down(400)]: {
			width: 130,
		},
	},
	debbieDot: {
		width: 50,
		[theme.breakpoints.down(400)]: {
			width: 35,
		},
	},
	hi9Logo: {
		width: 40,
	},
	drawerSection: {
		maxWidth: FULL_WIDTH,
		height: "100%",
	},
	leftMenu: {
		display: "flex",
		marginTop: 15,
		cursor: "pointer",
	},
	popularPrompt: {
		border: "1px solid grey",
		borderColor: theme.palette.primary.dark,
		backgroundColor: theme.palette.primary.light,
		borderRadius: 5,
		padding: "15px 10px",
		marginBottom: 20,
		cursor: "pointer",
	},
	micIcon: {
		width: 30,
		height: 30,
		marginRight: 10,
	},
	middleSection: {
		height: "100%",
		flexGrow: 1,
		display: "flex",
		flexDirection: "column",
	},
	inputWrapper: {
		display: "flex",
		alignItems: "center",
		// justifyContent: "center",
		marginBottom: 20,
		marginTop: "auto",
	},
	questionInput: {
		display: "flex",
		alignItems: "center",

		marginRight: "auto",
		marginLeft: "auto",
		width: "calc(100% - 100px)",
		[theme.breakpoints.down(800)]: {
			width: "calc(100% - 50px)",
		},
	},
	widWrapper: {
		flexGrow: 1,
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},
	widTitle: {
		marginBottom: 5,
		fontWeight: "bold",
	},
	widText: {
		backgroundColor: theme.palette.secondary.main,
		padding: "15px 40px",
		width: 500,
		margin: "20px 0px",
		borderRadius: 5,
		[theme.breakpoints.down(550)]: {
			width: 300,
		},
	},
	burgerMenuIcon: {
		cursor: "pointer",
		display: "none",
		[theme.breakpoints.down('md')]: {
			display: "block",
		},
	},
	topWrapper: {
		marginTop: 20,
		display: "flex",
		justifyContent: "center",
		[theme.breakpoints.down('md')]: {
			justifyContent: "space-between"
		},
	},
	messagesWrapperWrapper: {
		flexGrow: 1,
		display: "flex",
		justifyContent: "center",
		alignItems: "flex-end",
	},
	messagesWrapper: {
		marginBottom: 15,
		marginTop: 15,

		flexGrow: 1,
		display: "flex",
		flexDirection: "column",

		textAlign: "left",
		overflowY: "auto",
		"& > div:first-child": {
			marginTop: "auto",
		},
	},
	message: {
		padding: 15,
		margin: "10px 0px",
		borderRadius: 20,
	},
	messageWrapper: {
		marginRight: 50,
		marginLeft: 50,
		[theme.breakpoints.down(800)]: {
			marginRight: 25,
			marginLeft: 25,
		},
	},
	userMessage: {
		display: "flex",
		justifyContent: "flex-end",
		marginLeft: 50,
		[theme.breakpoints.up(1000)]: {
			marginLeft: 100,
		},
	},
	userMessageText: {
		backgroundColor: theme.palette.secondary.main,
	},
	botMessage: {
		display: "flex",
		justifyContent: "flex-start",
		marginRight: 50,
		[theme.breakpoints.up(1000)]: {
			marginRight: 100,
		},
	},
	botMessageText: {
		backgroundColor: "#bddaf4",
	},
	pacifierMessage: {
		display: "flex",
		padding: "15px 0",
	},
	buttonWrapper: {
		display: "flex",
		justifyContent: "center",
		marginRight: 10
	},
	buttonOption: {
		borderRadius: 15,
	},
	buttonsWrapper: {
		display: "flex",
		marginBottom: 15,
	}
}))

const VERSION = "5.0.9"
const POPULAR_PROMPTS = [
	"Estimate my Universal Credit",
	"Jobs near me",
	"When do my bins go?",
	// "caseworker",
]

const BASE_URL = "https://www.debbie.chat"
const LINKS = {
	aboutMe: `${BASE_URL}/about`,
	blog: `${BASE_URL}/blog`,
	data: `${BASE_URL}/data`,
	community: `https://discord.gg/9jh9Mgcayg`,
}

const MESSAGES_TYPES = {
	TEXT: "text",
	BUTTONS: "buttons"
}

function LeftSideSection(props: any) {
	const classes = useStyles();
	/*const navigate = useNavigate();

	const [user, setUser] = useState(getStorage())

	const login = () => {
		navigate("/login")
	}

	const logout = async () => {
		logoutStorage()
		setUser(null)
		props.setSessionId(null)
		await auth.signOut()
	} */

	return (
		<Box className={`${classes.leftSection}`}>
			<Box sx={{ width: "100%", display: "flex", flexDirection: "column" }}>
				<Box sx={{ p: 2, pb: 1, mb: "auto", textAlign: "center" }}>
					<Typography sx={{ mb: 1, fontSize: `${props.zoom}rem` }}>
						Get support for accessing services, knowledge, jobs, and skills by connecting with our helpful human coworkers.
					</Typography>
					<Link href="tel:03330150699" sx={{ textDecoration: "none", color: "inherit" }}>
						<Typography fontSize={`${1.3 * props.zoom}rem`}>0333 015 0699</Typography>
					</Link>
					<Typography fontSize={`${1.3 * props.zoom}rem`}
						sx={{ cursor: "pointer" }}
						onClick={() => { props.askQuestion("Contact me") }}
					>
						Contact Me
					</Typography>
				</Box>
				<Box sx={{ p: 2, pb: 1 }}>
					<Divider sx={{ backgroundColor: "white" }} />
					<Link href={LINKS.aboutMe} style={{ textDecoration: "none", color: "inherit" }}>
						<Box className={classes.leftMenu}>
							<RadioButtonUncheckedIcon sx={{ mr: 1 }} />
							<Typography sx={{ fontSize: `${props.zoom}rem` }}>About me</Typography>
						</Box>
					</Link>
					<Link target="_blank" href={LINKS.blog} style={{ textDecoration: "none", color: "inherit" }}>
						<Box className={classes.leftMenu}>
							<RssFeedIcon sx={{ mr: 1 }} />
							<Typography sx={{ fontSize: `${props.zoom}rem` }}>Blog</Typography>
						</Box>
					</Link>
					<Link href={LINKS.data} style={{ textDecoration: "none", color: "inherit" }}>
						<Box className={classes.leftMenu}>
							<DescriptionIcon sx={{ mr: 1 }} />
							<Typography sx={{ fontSize: `${props.zoom}rem` }}>Data</Typography>
						</Box>
					</Link>
					<Link target="_blank" href={LINKS.community} style={{ textDecoration: "none", color: "inherit" }}>
						<Box className={classes.leftMenu}>
							<PublicIcon sx={{ mr: 1 }} />
							<Typography sx={{ fontSize: `${props.zoom}rem` }}>Community</Typography>
						</Box>
					</Link>
					{/* {!user ?
						(<Box className={classes.leftMenu} onClick={login}>
							<LoginIcon sx={{ mr: 1 }} />
							<Typography sx={{ fontSize: `${props.zoom}rem` }}>Login</Typography>
						</Box>)
						: (<Box className={classes.leftMenu} onClick={logout}>
							<LogoutIcon sx={{ mr: 1 }} />
							<Typography sx={{ fontSize: `${props.zoom}rem` }}>Logout</Typography>
						</Box>)} */}
					<Box className={classes.leftMenu}>
						<InfoIcon sx={{ mr: 1 }} />
						<Typography sx={{ fontSize: `${props.zoom}rem` }}>Version: {VERSION}</Typography>
					</Box>
				</Box>
				<Box sx={{ p: 1 }}>
					<Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", backgroundColor: "white", p: 0.5 }}>
						<Typography variant="subtitle2" sx={{ color: "black" }}>Built by</Typography>
						<img src={Hi9Logo} alt="Hi9" className={classes.hi9Logo} />
						<Typography variant="subtitle2" sx={{ color: "black", mt: 0.5 }}>Supported by</Typography>
						<img src={PeopleHubLogo} alt="People Hub" className={classes.phLogo} />
						<img src={EUSocialFund} alt="EU - European Social Fund" className={classes.euLogo} />
					</Box>
				</Box>
			</Box>
		</Box>
	)
}

function RightSideSection(props: any) {
	const classes = useStyles();

	return (
		<Box className={`${classes.rightSection}`}>
			<Box>
				<Typography variant='h5' sx={{ mb: 4, fontSize: `${1.5 * props.zoom}rem` }}>Popular Prompts</Typography>
				{POPULAR_PROMPTS.map((prompt) => (
					<Box key={prompt} className={classes.popularPrompt} onClick={() => { props.askQuestion(prompt) }}>
						<Typography sx={{ fontSize: `${props.zoom}rem` }}>“{prompt}”</Typography>
					</Box>
				))}
			</Box>
		</Box>
	)
}

export default function Home() {
	const classes = useStyles();

	const [drawers, setDrawers] = useState({
		left: false,
		right: false,
	});

	const [sessionId, setSessionId] = useState<string | null>(null)

	const [question, setQuestion] = useState("");
	const [mic, setMic] = useState(false);

	const [messages, setMessages] = useState<any[]>([]);

	const [dropdownAnchor, setDropdownAnchor] = React.useState(null);
	const open = Boolean(dropdownAnchor);
	const [zoom, setZoom] = React.useState(1);

	function startRec() {
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		var SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition
		const recognition = new SpeechRecognition()

		// This runs when the speech recognition service starts
		recognition.onstart = function () {
			setMic(true)
		}

		recognition.onspeechend = function () {
			setMic(false)
			recognition.stop()
		}

		// This runs when the speech recognition service returns result
		recognition.onresult = function (event: any) {
			const transcript = event.results[0][0].transcript
			const confidence = event.results[0][0].confidence
			console.log(transcript, confidence)
			askQuestion(transcript)
		}
		// start recognition
		recognition.start()
	}

	useEffect(() => {
		if (!messages.length) {
			askQuestion("Hi")
		}
	}, [])

	useEffect(() => {
		// scrollToBottom()

		const messagesElt = document.querySelectorAll("#messages > div");
		if (!messagesElt || !messagesElt.length) return

		// console.log("messageElt", messageElt)
		// console.log("messageElt", messagesElt)
		// messageElt.getAttribute("class")

		// Find the first div with bot-message class from the bottom of the array
		let i = messagesElt.length - 1
		let section = null
		while (i >= 0) {
			if (messagesElt
				&& messagesElt[i]
				&& messagesElt[i].classList
				&& messagesElt[i].classList.contains("user-message")) {
				section = messagesElt[i]
				break
			}
			i--
		}

		if (!section) return
		// console.log("section", section)
		// Scroll to the top of that section
		section.scrollIntoView({ behavior: 'smooth', block: 'start' });
	}, [messages]);

	const toggleDrawer = (anchor: string, open: boolean) => (event: any) => {
		if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
			return;
		}

		setDrawers({ ...drawers, [anchor]: open });
	};

	const askQuestion = async (prompt: string | null = null) => {
		if (!prompt && !question && question.trim() === "") {
			return
		}

		if (drawers.right) setDrawers({ ...drawers, right: false })

		// console.log("Question: ", prompt || question, sessionId)
		// Check if the last message has the subType buttons, and pop it
		if (messages.length && messages[messages.length - 1].subType === MESSAGES_TYPES.BUTTONS) {
			messages.pop()
		}

		setMessages([
			...messages,
			{ text: prompt || question, type: "user" },
			{ text: "...", type: "pacifier" }
		])
		setQuestion("")

		let session = sessionId
		if (!session) {
			// const user = getStorage()
			// session = "website-" + (user ? user.id : Math.random().toString(36).substring(7))
			session = "website-" + Math.random().toString(36).substring(7)
			setSessionId(session)
		}

		console.log("session", session)

		// Dialogflow cx
		const response = await detectIntent({
			text: prompt || question,
			sessionId: session,
			isChat: true,
			type: MESSAGES_TYPES.TEXT,
		})
		/* const response = {
			messages: [
				{
					type: "text",
					text: "I can see your bins are collected on Fri 31 Mar and your recycling goes on Fri 7 Apr. Would you like to search for ANOTHER ADDRESS, or head back to my DESK?"
				}
			]
		} */
		// TODO: handle error


		// console.log("response", response)

		let updatedMessages = [...messages, { text: prompt || question, type: "user" }]
		response.messages.forEach((message: any) => {

			// If the message is text and it includes a link, replace the link text with a link
			if (message.type === MESSAGES_TYPES.TEXT && message.text.includes("http")) {
				const regex = /(https?:\/\/[^\s]+)/g
				const text = message.text.replace(regex, (url: string) => {
					return `<a href="${url}" target="_blank">${url}</a>`
				})
				updatedMessages.push({ text, subType: message.type, type: "bot" })
			} else {
				updatedMessages.push({ ...message, subType: message.type, type: "bot" })
			}
		})

		// console.log("last", updatedMessages[updatedMessages.length - 1].subType)
		if (updatedMessages[updatedMessages.length - 1].subType !== MESSAGES_TYPES.BUTTONS) {
			// console.log("generate buttons")
			const fullText = response.messages.filter((message: any) => message.type === MESSAGES_TYPES.TEXT).map((message: any) => message.text).join(".\n")
			// console.log("fullText", fullText)

			// Match all capital letters, except if they are preceded by a non-space, non-comma, non-period character, or if they are followed by a non-space, non-comma, non-period character
			const regex = /\b([A-Z][A-Z\s]+[A-Z])|(NO)\b/gm
			// const regex = /(?<![^\s.,])[A-Z][A-Z\s]{1,}[A-Z](?![^\s.,?!])|(\bNO\b)/gm
			const buttons = fullText.match(regex)
			// console.log("buttons", buttons)

			if (buttons && buttons.length) updatedMessages.push({ type: "bot", subType: MESSAGES_TYPES.BUTTONS, options: buttons })
		}

		setMessages(updatedMessages)
		// console.log("updatedMessages", updatedMessages)
	}

	function wrapperHeight(): string {
		let height = "100vh"
		if (isMobile && isIOS) {
			height = "90vh"
		} else if (isMobile) {
			height = "93vh"
		}
		// console.log("height", isMobile, isIOS, height)
		return height
	}

	function smallerText() {
		setZoom(Math.max((+zoom - .1), 0.8)) // 0.8 is the minimum
	}
	function biggerText() {
		setZoom(Math.min((+zoom + .1), 1.5)) // 1.5 is the maximum
	}
	function resetText() {
		setZoom(1)
	}

	const handleClickMore = (event: any) => {
		setDropdownAnchor(event.currentTarget);
	};

	const handleCloseMoreDropdown = () => {
		setDropdownAnchor(null);
	};

	const clearChat = () => {
		setQuestion("")
		setMessages([])
		setSessionId(null)
		handleCloseMoreDropdown()
	}

	return (
		<Box className={classes.wrapper}
			sx={{
				height: wrapperHeight(),
			}}
		>
			<Box className={classes.nonDrawerSection}>
				<LeftSideSection
					setSessionId={(value: any) => { setSessionId(value) }}
					askQuestion={askQuestion}
					zoom={zoom}
				/>
			</Box>

			<Box className={classes.middleSection}>
				<Box className={classes.topWrapper}>

					<Box sx={{ ml: 1 }}
						className={classes.burgerMenuIcon}
						onClick={toggleDrawer('left', true)}
					>
						<img src={DebbieDot} alt="menu" className={classes.debbieDot} />
					</Box>
					<img src={DebbieLogo} alt="Logo" className={classes.debbieLogo} />
					<Box sx={{ mr: 1 }}
						className={classes.burgerMenuIcon}
						onClick={toggleDrawer('right', true)}
					>
						<img src={DebbieDot} alt="menu" className={classes.debbieDot} />
					</Box>
				</Box>
				{/* Revert back */}

				{messages.length !== 0 &&
					<Box id="messages" className={classes.messagesWrapper}>
						{messages.map((message, index) => {
							switch (message.type) {
								case "user":
									return (
										<Box key={index} className={`${classes.messageWrapper} user-message`}>
											<Box className={`${classes.userMessage}`}>
												<Typography
													className={`${classes.message} ${classes.userMessageText}`}
													sx={{ fontSize: `${zoom}rem` }}
												>
													{message.text}
												</Typography>
											</Box>
										</Box>
									)
								case "bot":
									return (
										<Box key={index} className={`${classes.messageWrapper} bot-message`}>
											{
												(() => {
													switch (message.subType) {
														case MESSAGES_TYPES.TEXT:
															return (
																<Box className={`${classes.botMessage}`}>
																	<Typography
																		className={`${classes.message} ${classes.botMessageText}`}
																		sx={{ whiteSpace: "pre-wrap", fontSize: `${zoom}rem` }}
																		dangerouslySetInnerHTML={{ __html: message.text }}
																	>
																		{/* {`${message.text}`} */}
																	</Typography>
																</Box>)
														case MESSAGES_TYPES.BUTTONS:
															return (
																<Box className={`${classes.buttonsWrapper} pacifier-message`}>
																	{message.options.map((option: any, index: number) => (
																		<Box key={index} className={classes.buttonWrapper}>
																			<Button
																				variant="contained"
																				color="primary"
																				size="small"
																				onClick={() => { askQuestion(option) }}
																				className={classes.buttonOption}
																				sx={{ fontSize: `${0.8 * zoom}rem` }}
																			>
																				{option}
																			</Button>
																		</Box>
																	))}
																</Box>
															)
													}
												})()
											}
										</Box>
									)
								case "pacifier":
									return (
										<Box key={index} className={classes.messageWrapper}>
											<Box className={`${classes.pacifierMessage} ${classes.message}`}>
												<div className="bouncing-loader">
													<div></div>
													<div></div>
													<div></div>
												</div>
											</Box>
										</Box>
									)
							}
						})}
					</Box>
				}

				<Box className={classes.inputWrapper}>
					<Box className={classes.questionInput}>
						<TextField
							fullWidth
							id="question-input"
							label=""
							placeholder="Type something ..."
							value={question}
							onChange={(event) => {
								setQuestion(event.target.value);
							}}
							onKeyDown={(event) => {
								if (event.key === "Enter") {
									askQuestion()
								}
							}}
							InputProps={{
								endAdornment: (
									<>
										<InputAdornment position="end">
											<SendOutlinedIcon
												fontSize="medium"
												sx={{ cursor: "pointer" }}
												onClick={() => askQuestion()} />
										</InputAdornment>
										<InputAdornment position="end">
											{/* <img src={mic} alt="mic" className={classes.micIcon}  /> */}
											<MicRoundedIcon
												// fontSize="large"
												sx={{ cursor: "pointer", color: mic ? "red" : "inherit", fontSize: 28 }}
												onClick={startRec}
											/>
										</InputAdornment>
									</>
								),
							}}
							sx={{
								"& .MuiOutlinedInput-root": {
									fontSize: `${zoom}rem`,
								}
							}}
						// className={classes.questionInput}
						/>
						<MoreVertIcon
							color="primary" fontSize="large"
							sx={{ cursor: "pointer", ml: .5 }}
							onClick={handleClickMore}
						/>
						<Menu
							id="basic-menu"
							anchorEl={dropdownAnchor}
							open={open}
							onClose={handleCloseMoreDropdown}
							MenuListProps={{
								'aria-labelledby': 'basic-button',
							}}
						>
							<MenuItem onClick={clearChat}>
								<ListItemIcon>
									<ClearIcon fontSize="small" />
								</ListItemIcon>
								<ListItemText sx={{ "& span": { fontSize: `${zoom}rem` } }}>
									Clear conversation
								</ListItemText>
							</MenuItem>
							<MenuItem onClick={biggerText}>
								<ListItemIcon>
									<TextIncreaseIcon fontSize="small" />
								</ListItemIcon>
								<ListItemText sx={{ "& span": { fontSize: `${zoom}rem` } }}>Increase text size</ListItemText>
							</MenuItem>
							<MenuItem onClick={smallerText}>
								<ListItemIcon>
									< TextDecreaseIcon fontSize="small" />
								</ListItemIcon>
								<ListItemText sx={{ "& span": { fontSize: `${zoom}rem` } }}>Decrease text size</ListItemText>
							</MenuItem>
							<MenuItem onClick={resetText}>
								<ListItemIcon>
									< RestartAltIcon fontSize="small" />
								</ListItemIcon>
								<ListItemText sx={{ "& span": { fontSize: `${zoom}rem` } }}>Reset text size</ListItemText>
							</MenuItem>
						</Menu>
					</Box>
				</Box>
			</Box>

			<Box className={classes.nonDrawerSection}>
				<RightSideSection askQuestion={(prompt: string) => { askQuestion(prompt) }} zoom={zoom} />
			</Box>

			<Drawer
				anchor="left"
				open={drawers["left"]}
				onClose={toggleDrawer("left", false)}
			>
				<Box className={classes.drawerSection}>
					<LeftSideSection
						setSessionId={(value: any) => { setSessionId(value) }}
						askQuestion={askQuestion}
						zoom={zoom}
					/>
				</Box>
			</Drawer>
			<Drawer
				anchor="right"
				open={drawers["right"]}
				onClose={toggleDrawer("right", false)}
			>
				<Box className={classes.drawerSection}>
					<RightSideSection askQuestion={(prompt: string) => { askQuestion(prompt) }} zoom={zoom} />
				</Box>
			</Drawer>
		</Box >
	)
}
