import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import { createTheme } from "@mui/material/styles";
import defaultTheme from "./theme";
import { Box } from '@mui/material';

import Home from "./pages/Home";
import Login from "./pages/Login";

function App() {
	return (
		<StyledEngineProvider injectFirst>
			<ThemeProvider theme={createTheme(defaultTheme)}>
				<CssBaseline />
				<Box /* style={{ height: "100vh"}} */>
					<Router>
						<Routes>
							<Route exact-path path="/" element={<Home />} />
							<Route path="/login" element={<Login />} />

							<Route path="/data" Component={() => {
								window.location.href = 'https://www.debbie.chat/data';
								return null;
							}} />
							<Route path="/blog" Component={() => {
								window.location.href = 'https://www.debbie.chat/blog';
								return null;
							}} />
							<Route path="/about" Component={() => {
								window.location.href = 'https://www.debbie.chat/about';
								return null;
							}} />

							<Route path="*" element={<Home />} />
						</Routes>
					</Router>
				</Box>
			</ThemeProvider>
		</StyledEngineProvider>
	);
}

export default App;
