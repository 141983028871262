import { User } from "./types";

const DEFAULT_KEY = "user"

export const loginStorage = (key: string = DEFAULT_KEY, data: User) => {
	localStorage.setItem(key, JSON.stringify(data))
};

export const logoutStorage = (key: string = DEFAULT_KEY, redirect = false) => {
	localStorage.removeItem(key)

	if (redirect) window.location.replace("/")
}

export const getStorage = (key: string = DEFAULT_KEY) => {
	const data = localStorage.getItem(key)
	return data ? JSON.parse(data as string) : null
};
