import axios from 'axios'

export async function detectIntent(data: any) {
	const url = "https://europe-west2-debbie-project.cloudfunctions.net/debbie_land/detectIntent"
	// const url = "http://127.0.0.1:5001/debbie-project/europe-west2/debbie_land/detectIntent"
	try {
		const response = await axios.post(url, data)
		// console.log(response.data);
		return response.data.data
	} catch (error: any) {
		console.error(error)
		throw new Error(error.response.data.message)
	}
}

	